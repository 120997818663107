import React from 'react'
import { propertyForSaleURL } from '../../../site/urls'
import SearchResultsTemplate from '../../../templates/search-results-template'

const PropertySale = (props) => {
    const location = props.location
  return (
    <SearchResultsTemplate
    locationName={propertyForSaleURL}
    location={location}
    pCategoryType={["residential","auction","new_developments"]}
    mainDepartment="residential"
    pType="sales"
    pSubPath="for-sale"
    pStatus={["For Sale","Under Offer"]}
  />
  )
}

export default PropertySale